import { useCallback, useEffect, useMemo, useState } from 'react'
import { useCookies } from 'react-cookie'

import Card from '@/components/Card/Card'
import SaltInfoButton from '@/components/Salt/SaltInfoButton'
import localStorageService from '@/services/localStorage.service'

import SaltLoader from './SaltLoader'
import PreviousCardInfo from './SaltPreviousCardInfo'
import SaltVoteButton from './SaltVoteButton'

const MONTH = 2592000

export type SaltCardType = {
  name: string
  vote: string
  imageUrl: string
  saltScore?: number
  total: number
  currentSaltScore?: number
  tcgUrl: string
  ckUrl: string
  csiUrl: string
}

type Props = {
  voteCount: number
}

const SaltBody = ({ voteCount }: Props) => {
  const [card, setCard] = useState<SaltCardType>()
  const [cookies, setCookie] = useCookies(['count', 'previousCards', 'mines', 'noMines', 'total'])
  const [loading, setLoading] = useState(true)
  const [voteLoading, setVoteLoading] = useState(false)
  const [previousCard, setPreviousCard] = useState<SaltCardType>()
  const [previousVote, setPreviousVote] = useState(0)

  const previousCards = useMemo(() => {
    if (!cookies.count) return {}
    return localStorageService.getJSON('previousCards') || {}
  }, [cookies.count, cookies.previousCards]) // Moving on to local storage but still using cookies if no local storage yet

  const fetchCard = useCallback(
    async (rerolls = 0) => {
      setLoading(true)
      let needsRetry = false
      let param = voteCount
      if (rerolls > 0) {
        // Special value which will cause card selection to choose from among all nonland cards
        param = -1
      }
      await fetch(`https://us-central1-edhrec-salt.cloudfunctions.net/api/card?n=${param}`)
        .then((resp) => resp.json())
        .then((data) => {
          if (!data.error) {
            if (rerolls <= 3 && data.name in previousCards) {
              // Found a card already voted on, reroll for a new card
              needsRetry = true
              rerolls += 1
            } else {
              setCard(data)
              setCookie('total', data.total.toString(), { path: '/', maxAge: MONTH })
              rerolls = 0
            }
          }
        })
      if (needsRetry) {
        await fetchCard(rerolls)
      } else setLoading(false)
    },
    [voteCount, setCookie, previousCards],
  )

  useEffect(() => {
    fetchCard()
  }, [])

  const handleVote = useCallback(
    (flag: boolean, vote: number) => {
      if (flag) setVoteLoading(true)
      else {
        setPreviousCard(card)
        setPreviousVote(vote)
        setVoteLoading(false)
        setCookie('count', voteCount + 1, { path: '/', maxAge: MONTH })
        if (card) localStorageService.setJSON('previousCards', { ...previousCards, [card.name]: vote })
        if (voteCount + 1 >= 50 && !cookies.mines) setCookie('mines', true, { path: '/', maxAge: MONTH })
        fetchCard()
      }
    },
    [card, fetchCard, previousCards, setCookie, voteCount, cookies.mines],
  )

  if (!card) return <SaltLoader text='Loading a card' />

  return (
    <div className='align-items-center d-flex flex-column gap-3 h-100 pb-3'>
      <SaltInfoButton />
      {loading && <SaltLoader text='Loading a card' />}
      {voteLoading && <SaltLoader text='Submitting your vote' />}
      <h2 className='d-none d-lg-block'>{card.name}</h2>
      <Card name={card.name} />
      <div>How salty does this card make you in EDH?</div>
      <div className='d-flex gap-2'>
        {[0, 1, 2, 3, 4].map((salt) => (
          <SaltVoteButton
            setVoteLoading={handleVote}
            vote={card.vote}
            salt={salt}
            key={`${salt}-${card.name}`}
            voted={!loading && previousCards[card.name] === salt}
          />
        ))}
      </div>
      {previousCard && <PreviousCardInfo card={previousCard} vote={previousVote} />}
    </div>
  )
}

export default SaltBody
