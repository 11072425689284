import classNames from 'classnames'

import styles from './Panel.module.scss'

type Props = {
  children: React.ReactNode
  className?: string
}

const Panel = ({ children, className }: Props) => <div className={classNames(styles.panel, className)}>{children}</div>

export default Panel
