import classNames from 'classnames'
import React, { useMemo } from 'react'
import { useCookies } from 'react-cookie'

import Container from '@/components/Container/Container'
import { ExplicitHead } from '@/components/DefaultHead/DefaultHead'
import Main from '@/components/Main/Main'
import Panel from '@/components/Panel/Panel'
import SaltBody from '@/components/Salt/SaltBody'
import SaltVoteCount from '@/components/Salt/SaltVoteCount'
import SimpleHeader from '@/components/SimpleHeader/SimpleHeader'

import styles from './salt.module.scss'

const FINISHED = true

const Privacy = () => {
  const [cookies] = useCookies(['count', 'total'])

  const voteCount = useMemo(() => {
    return Number(cookies.count) || 0
  }, [cookies.count])

  return (
    <Main>
      <Container>
        <ExplicitHead title='Salt Vote | EDHREC' description='EDHREC Salt Vote' keywords='EDHREC,salt,score,vote' />
        <div className='align-items-end d-flex mb-2'>
          <SimpleHeader>Salt Vote</SimpleHeader>
          {!FINISHED && <SaltVoteCount voteCount={voteCount} />}
        </div>
        <Panel className={classNames({ [styles.panel]: !FINISHED })}>
          {FINISHED ? (
            <div className={styles.info}>
              <h2 className='mb-3'>Thank you!</h2>
              <p>
                That&apos;s all for this round of salt voting. Keep and eye on EDHREC and Archidekt for the updated
                results once we process the data. If you are sad to see the polls end, don&apos;t worry! We&apos;ll be
                back for more salt voting and other data collection soon.
              </p>
              <p>Thanks for joining us in the salt mines!</p>
            </div>
          ) : (
            <SaltBody voteCount={voteCount} />
          )}
          {
            <div className='d-flex justify-content-center'>
              <div className={styles.voteCount}>1,013,376 votes</div>
            </div>
          }
        </Panel>
      </Container>
    </Main>
  )
}

export default Privacy
