import classNames from 'classnames'

import styles from './Container.module.scss'

type Props = {
  children: React.ReactNode
  className?: string
  small?: boolean
}

const Container = ({ children, className, small }: Props) => (
  <div className={classNames(styles.container, { [className as string]: className, [styles.small]: small })}>
    {children}
  </div>
)

export default Container
