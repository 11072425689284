import classNames from 'classnames'
import { useState } from 'react'

import styles from './SaltInfoButton.module.scss'

const SaltInfoButton = () => {
  const [infoOpen, setInfoOpen] = useState(false)

  return (
    <>
      <button
        aria-label='Learn about the EDHREC salt score'
        title='Info'
        className={styles.trigger}
        onClick={() => setInfoOpen(true)}
      >
        ?
      </button>

      {infoOpen && (
        <div className={classNames(styles.infoContainer, 'shadow-sm')}>
          <div className={styles.content}>
            <button onClick={() => setInfoOpen(false)} className={styles.closeContainer}>
              x
            </button>
            <iframe
              className={styles.frameStyle}
              src='https://www.youtube.com/embed/sI_JihFQEqw'
              title='YouTube video player'
              frameBorder='0'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
              allowFullScreen
            />
          </div>
        </div>
      )}
    </>
  )
}

export default SaltInfoButton
