import Spinner from '@/components//Spinner/Spinner'

type Props = {
  text?: string
}

const Loader = ({ text }: Props) => (
  <div className='align-items-center d-flex flex-column gap-3 justify-content-center'>
    <Spinner size='xl' />
    {text && <p>{text}</p>}
  </div>
)

export default Loader
